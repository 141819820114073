import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { LayoutService } from './utils';

export const NB_CORE_PROVIDERS = [
	LayoutService,
];

@NgModule({
	imports: [
		CommonModule
	],
	exports: [],
	declarations: [],
})
export class CoreModule {
	constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
		throwIfAlreadyLoaded(parentModule, 'CoreModule');
	}

	static forRoot(): ModuleWithProviders<CoreModule> {
		return {
			ngModule: CoreModule,
			providers: [
				...NB_CORE_PROVIDERS,
			],
		};
	}
}
