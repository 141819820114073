import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Profile, User, UserManager } from 'oidc-client';
import { CustomerSignalRService } from '../customers/signalr/customer-signalr.service';
import { ManagerSignalRService } from '../management/signalr/manager-signalr.service';
import { Constants, RolesEnum } from '../shared/Constants';
import { ApiClientService } from '../shared/services/api-client.service';
import { GuidService } from '../shared/services/guid.service';
import { LoadingService } from '../shared/services/loading.service';
import { LocalStoreManager } from '../shared/services/local-store-manager.service';
import { TenantCacheService } from '../shared/services/tenant-cache.service';
import { UserService } from '../shared/services/user.service';
import { authConfig } from './auth.config';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private user: User | null;
	private manager;

	public get isAnnonymousLogin(): boolean {
		return this.localStorage.getData(Constants.ANNONYMOUS_LOGIN);
	};

	get currentUser(): User {
		return this.localStorage.getData(Constants.CURRENT_USER);
	}

	get accessToken(): string {
		return this.localStorage.getData(Constants.ACCESS_TOKEN);
	}


	constructor(private router: Router, private tenantCacheService: TenantCacheService, 
		private customerSignalRService: CustomerSignalRService, 
		private loadingService: LoadingService,
		private managerSignalRService: ManagerSignalRService,
		private apiClientService: ApiClientService,
		private guidService: GuidService, 
		private userService: UserService, private localStorage: LocalStoreManager) {
		// authConfig.iframeNavigator = new IOCFrameNavigator();

		this.manager = new UserManager(authConfig);
		this.manager.events.addUserLoaded((data) => {
			if (data && data.access_token) {
				this.tenantCacheService.setCurrentTenantId(data.profile.tenantId);
				this.localStorage.savePermanentData(data.access_token, Constants.ACCESS_TOKEN);
			}
		});

		this.manager.events.addAccessTokenExpired(() => {
			this.manager.removeUser().then(() => {
				console.log('addAccessTokenExpired');
				this.cleanStateAndRedirect();
			});
		});

		this.manager.events.addAccessTokenExpired((data)=> {
			console.log('addAccessTokenExpired');
			console.log(data);
		});

		this.manager.events.addSilentRenewError((data)=> {
			console.log('addSilentRenewError');
			console.log(data);
		});


		this.manager.events.addUserSessionChanged((data)=> {
			console.log('addUserSessionChanged');
			console.log(data);
		});

		this.manager.events.addSilentRenewError((error) => {
			console.log('addSilentRenewError1');
			this.manager.removeUser().then(() => {
				console.log('addSilentRenewError2');
				this.cleanStateAndRedirect();
			});
		});
		this.manager.events.addUserSignedOut(() => {
		console.log('addUserSignedOut');

			this.manager.removeUser().then(() => {
				console.log('removeUser');
				this.cleanStateAndRedirect();
			});
		});

		// this.manager.events.addUserLoaded(() => {
		// 	this.manager.removeUser().then(() => {
		// 		this.cleanStateAndRedirect();
		// 	});
		// });
	}

	getRole(): RolesEnum {
		if (this.isAnnonymousLogin) {
			return RolesEnum.guest;
		}
		return this.currentUser?.profile?.role;
	}

	getCurrentLoggedUser(): Profile {
		if (this.currentUser) {
			return this.currentUser.profile;
		}
		return null;
	}

	authenticate(): void {
		this.loadingService.showLoader();
		try {
			this.manager.signinRedirect();
		} catch (error) {
			this.loadingService.hideLoader();
		}
	}

	authWithFacebook(): void {
		this.manager.signinRedirect({
			extraQueryParams: {
			  useExternalProvider: "facebook",
			  acr_values: 'idp:Facebook',
			}});
	}

	authWithGoogle(): void {
		this.manager.signinRedirect({
			extraQueryParams: {
			  useExternalProvider: "google",
			  acr_values: 'idp:Google',
			}});
	}

	async authAsGuest(): Promise<void> {
		const storedAnnonymousUser = this.localStorage.getData(Constants.CURRENT_LOGGED_ANNONYMOUS_USER) as User;
		if (storedAnnonymousUser && new Date(storedAnnonymousUser?.expires_at) > new Date()) {
				storedAnnonymousUser.expires_at = new Date().setDate( new Date().getDate() + 1);
				this.user = storedAnnonymousUser;
				this.setAnnonymousLoginFlag(true);
				this.connectSignalRHubs(RolesEnum.guest);
				this.localStorage.savePermanentData(this.user, Constants.CURRENT_USER);
				this.localStorage.savePermanentData(this.user, Constants.CURRENT_LOGGED_ANNONYMOUS_USER);

				this.routeToCorrectModule(RolesEnum.guest);
		} else {
			await this.getAnnonymousUser().then((user) => {
				if (user) {
					user.expires_at = new Date().setDate( new Date().getDate() + 1);
					this.user = user;
					this.setAnnonymousLoginFlag(true);
					this.connectSignalRHubs(RolesEnum.guest);
					this.localStorage.savePermanentData(this.user, Constants.CURRENT_USER);
					this.localStorage.savePermanentData(this.user, Constants.CURRENT_LOGGED_ANNONYMOUS_USER);
					this.routeToCorrectModule(RolesEnum.guest);
				}
			}).catch((err) => {
				console.log('Error fetching annonymous user');
				this.setAnnonymousLoginFlag(false);
	
			});
		}
	}

	renew(): void {
		this.manager.signinSilent();
	}

	async completeAuthentication(): Promise<void> {
		this.loadingService.hideLoader();
		this.user = await this.manager.signinRedirectCallback();
		const role = this.user.profile.role;
		this.connectSignalRHubs(role);
		this.localStorage.savePermanentData(this.user, Constants.CURRENT_USER);
	}

	logout(): void {
		if (this.isAnnonymousLogin) {
			this.setAnnonymousLoginFlag(false);
			this.localStorage.deleteData(Constants.ANNONYMOUS_USER_ID);
			this.localStorage.deleteData(Constants.CURRENT_USER);
			this.router.navigate(['/auth']);
		} else {
			this.manager.signoutRedirect().then(() => {
				this.manager.clearStaleState();
			});
		}
	}

	cleanStateAndRedirect(): void {
		this.manager.clearStaleState();
		// this.isLoginSubject.next(false);
		// this.localStorage.deleteData(DBkeys.CURRENT_USER);
		this.localStorage.deleteData(Constants.ACCESS_TOKEN);
		this.router.navigate(['/login']);
	}

	public routeToCorrectModule(role, customerId?: string): void {
		const directLink = this.localStorage.getData(Constants.DIRECT_LINK);
		this.localStorage.deleteData(Constants.DIRECT_LINK);
		if (directLink) {
			// WE MIGHT WANT TO SWITCH HERE FROM HREF TO this.router.navigate..
			window.location.href = directLink;
			return;
		}
		if (role) {
			switch (role) {
				case RolesEnum.admin:
					this.router.navigate(['/admin/dashboard'], { replaceUrl: true });
					break;
				case RolesEnum.customer:
					if (!customerId) {
						this.router.navigate(['/auth'], { replaceUrl: true });
						return;
					}
					this.router.navigate([`/customer/${customerId}/scan`], { replaceUrl: true });
					break;
				case RolesEnum.employee:
					this.router.navigate(['/management/orders'], { replaceUrl: true });
					break;
				case RolesEnum.manager:
					this.router.navigate(['/management/dashboard'], { replaceUrl: true });
					break;
				case RolesEnum.guest:
					this.router.navigate(['/customer/annonymous/scan'], { replaceUrl: true });
					break;
				default:
					break;
			}
		}
	}

	public async getAuthUser(): Promise<User> {
		if (!this.manager) {
			return null;
		}
		return await this.manager.getUser();
	}

	public setAnnonymousLoginFlag(isAnnonymousLogin: boolean): void {
		if (isAnnonymousLogin) {
			this.localStorage.savePermanentData(true, Constants.ANNONYMOUS_LOGIN);
		} else {
			this.localStorage.deleteData(Constants.ANNONYMOUS_LOGIN);
		}
	}

	async isAuthenticated(): Promise<boolean> {
		if (this.isAnnonymousLogin) {
			return true;
		}
		const user = await this.manager.getUser();
		if (!user) {
			this.router.navigate(['/auth']);
			return false;
		}
		// const currentUserStored = this.localStorage.getDataObject<any>(DBkeys.CURRENT_USER);
		this.user = user;
		this.user.profile.id = '123'
		this.tenantCacheService.currentTenantId = user.profile.tenantId;

		return !this.user.expired;
	}

	private connectSignalRHubs(role: RolesEnum): void {
		switch (role) {
			case RolesEnum.manager:
			case RolesEnum.employee:
				this.managerSignalRService.startConnection(this.user.access_token);
				break;
			case RolesEnum.customer:
			case RolesEnum.guest:
				this.customerSignalRService.startConnection(this.user.access_token);
				break;
			default:
				break;
		}
	}

	private async getAnnonymousUser(): Promise<User> {
		let annonymousUserId = this.localStorage.getData(Constants.ANNONYMOUS_USER_ID);
		if (!annonymousUserId) {
			annonymousUserId = this.guidService.generateGuid()
		}
		try {
			const result = await this.apiClientService.postIdentityData<User, any>('guest/login', { userId: annonymousUserId });
			if (result) {
				return result;
			}
		} catch (error) {
			return null;
		}

	}

}
