import { Injectable } from '@angular/core';
import { Constants } from '../Constants';
import { LocalStoreManager } from './local-store-manager.service';

@Injectable({
	providedIn: 'root',
})
export class SharedService {
	languages: Language [] = [
		{
			id: 1,
			name: 'English',
			shortName: 'en',
			icon: 'en.png',
			displayName: 'En'
		},
		{
			id: 2,
			name: 'Bosanski',
			shortName: 'bos',
			icon: 'bos.png',
			displayName: 'BiH'
		}
		// {
		// 	id: 3,
		// 	name: 'Deutsch',
		// 	shortName: 'de',
		// 	icon: 'de.png'
		// },
	];
	constructor(private localStoreManager: LocalStoreManager) {

	}

	getCurrentLanguage(): string {
		let currentLanguage = this.localStoreManager.getData(Constants.LANG);
		if (currentLanguage === null) {
			currentLanguage = 'en';
			this.localStoreManager.savePermanentData(currentLanguage, Constants.LANG);
			return currentLanguage;
		} else {
			return currentLanguage;
		}
	}

	getLanguages(): Language[] {
		return this.languages;
	}

	getLanguageByShortName(lang: string): Language {
		return this.languages.find(x => x.shortName === lang);
	}

	changeLanguage(newLanguage: string) {
		if (newLanguage) {
			this.localStoreManager.savePermanentData(newLanguage, Constants.LANG);
		}
	}

	saveThemePreference(themeName: string): void {
		if (themeName.length) {
			this.localStoreManager.savePermanentData(themeName, Constants.THEME_PREFERENCE);
		}
	}

	getThemePreference(): string {
		const themePreference = this.localStoreManager.getData(Constants.THEME_PREFERENCE);
		if (themePreference) {
			return themePreference;
		}
		return Constants.DEFAULT_THEME;
	}
}

export class Language {
	id: number;
	name: string;
	shortName: string;
	icon: string;
	displayName: string;
}
