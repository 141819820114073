import { Allergen, SpiceLevel } from './models/models';

export class Constants {
	public static readonly ACCESS_TOKEN = 'access_token';
	public static readonly DIRECT_LINK = 'direct_link';

	public static readonly CURRENT_TENANT = 'current_tenant';
	public static readonly DEFAULT_HOME_URL = '/management';
	public static readonly AUTHENTICATED = 'authenticated';
	public static readonly CURRENT_USER = 'current_user';
	public static readonly CURRENT_LOGGED_ANNONYMOUS_USER = 'current_logged_annonymous_user';
	public static readonly ANNONYMOUS_LOGIN = 'annonymous_login';
	public static readonly LANG = 'lang';
	public static readonly THEME_PREFERENCE = 'theme_preference';

	public static readonly DEFAULT_TIMEZONE = 'Europe/Berlin';
	public static readonly ANNONYMOUS_USER_ID = 'annonymous_user_id';

	public static readonly DEFAULT_THEME = 'default';


}

export enum RolesEnum {
	admin = 'Admin',
	customer = 'Customer',
	manager = 'Manager',
	employee = 'Employee',
	guest = 'Guest',
	any = 'Any'
}

export const SPICE_LEVEL_LIST: SpiceLevel[] = [
	{
		key: 1,
		text: 'Mild',
		icon: ''
	},
	{
		key: 2,
		text: 'Medium',
		icon: ''
	},
	{
		key: 3,
		text: 'Hot',
		icon: ''
	},
	{
		key: 4,
		text: 'Extra hot',
		icon: ''
	}
];

export const ALLERGENES_LIST: Allergen[] = [
	{
		key: 'A',
		text: 'Gluten-containing grains'
	},
	{
		key: 'B',
		text: 'Crustaceans'
	},
	{
		key: 'C',
		text: 'Egg'
	},
	{
		key: 'D',
		text: 'Fish'
	},
	{
		key: 'E',
		text: 'Peanut'
	},
	{
		key: 'F',
		text: 'Soy'
	},
	{
		key: 'G',
		text: 'Milk or lactose'
	},
	{
		key: 'H',
		text: 'Edible nuts'
	},
	{
		key: 'L',
		text: 'Celery'
	},
	{
		key: 'M',
		text: 'Mustard'
	},
	{
		key: 'N',
		text: 'Sesame'
	},
	{
		key: 'O',
		text: 'Sulphites'
	},
	{
		key: 'P',
		text: 'Lupines'
	},
	{
		key: 'R',
		text: 'Molluscs'
	},
];