<nb-card class="no-border">
	<nb-card-header class="no-border">

		<b>User preferences</b>
		<!-- <button (click)="savePreferences()" style="float: right" type="button" status="success" nbButton>Save</button> -->
	</nb-card-header>
	<nb-card-body class="no-border">
		<div class="form-group d-flex items-center justify-space-between">
			<label class="d-block mr-3 mb-0">Change Theme</label>
			<nb-select style="width: 150px" status="primary" [selected]="currentTheme" (selectedChange)="changeTheme($event)">
				<nb-option *ngFor="let theme of themes" [value]="theme.value">{{ theme.name }}</nb-option>
			</nb-select>
		</div>

		<div class="form-group d-flex items-center justify-space-between">
			<label class="d-block mr-3 mb-0">Choose Language</label>
			<nb-select style="width: 150px" placeholder="Select Language" (selectedChange)="languageChanged($event)" [(selected)]="selectedItem">
				<nb-select-label>
					<!-- <nb-icon icon="log-in-outline"></nb-icon> {{ selectedItem }} -->
					<img class="mr-5" height="20px" src="../../../../assets/flags/{{currentLanguage.icon}}">
					<span>{{currentLanguage.displayName}}</span>
				</nb-select-label>
				<nb-option *ngFor="let language of languages" [value]="language.shortName">
					<img class="mr-5" height="20px" src="../../../../assets/flags/{{language.icon}}">
					<span>{{language.displayName}}</span>
				</nb-option>
			</nb-select>
		</div>
	</nb-card-body>
</nb-card>
