import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Order } from '../../../customers/order/models/models';

@Injectable({providedIn: 'root'})
export class IncommingOrderService {
	private orderArrivedSource = new BehaviorSubject<Order>(null);
	public orderArrived$ = this.orderArrivedSource.asObservable();


	newOrderArrived(order: Order): void {
		if (order && order.id) {
			this.orderArrivedSource.next(order);
		}
	}
}