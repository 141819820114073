import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class GuidService {

	generateGuid(): string {
		return crypto.randomUUID();
	}
}
