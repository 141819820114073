import { WebStorageStateStore } from 'oidc-client';
import { environment } from '../../environments/environment';

export const authConfig = {
	authority: 'https://identity.hasty-tasty.net',
	client_id: environment.clientId,
	response_type: 'code',
	scope: 'openid profile offline_access backend_api',
	client_secret: 'SuperSecretPassword',

	redirect_uri: environment.redirectUri,
	post_logout_redirect_uri: environment.postLogoutRedirectUri,

	// loadUserInfo: true,
	automaticSilentRenew: true,
	silent_redirect_uri: environment.silentRedirectUri,
	accessTokenExpiringNotificationTime: 120,

	// The number of seconds before an access token is to expire to raise the accessTokenExpiring event.
	// accessTokenExpiringNotificationTime: 60 * 5,
	revokeAccessTokenOnSignout: true,

	userStore: new WebStorageStateStore({ store: localStorage })
}
