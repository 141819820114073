import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GuidService } from './guid.service';

@Injectable({
	providedIn: 'root',
})
export class NotificationsService {
	constructor(private guidService: GuidService) {
		// this.addNewNotification('sample notification', 'type');
	}
	public notifications: Notification[] = [];
	public notifications$ = new BehaviorSubject<Notification[]>([]);

	addNewNotification(text: string, type: string): void {
		const notification: Notification = {
			id: this.guidService.generateGuid(),
			text: text,
			header: 'header',
			type: type,
			read: false
		};
		this.notifications.push(notification);
		this.notifications$.next(this.notifications);
	}

	readNotification(id: string): void {
		const found = this.notifications.find(x => x.id === id);
		if (found) {
			found.read = true;
		}
		this.notifications$.next(this.notifications);
	}
	
}

export class Notification {
	id: string;
	text: string;
	header: string;
	type: string;
	read: boolean;
}