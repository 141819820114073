import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Profile } from 'oidc-client';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { LayoutService } from '../../../@core/utils';
import { AuthService } from '../../../auth/auth.service';
import { RolesEnum } from '../../../shared/Constants';
import { NotificationsService } from '../../../shared/services/notifications.service';
import { Language, SharedService } from '../../../shared/services/shared.service';

@Component({
	selector: 'ngx-header',
	styleUrls: ['./header.component.scss'],
	templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
	private destroy$: Subject<void> = new Subject<void>();
	subs = new Subscription();
	public readonly materialTheme$: Observable<boolean>;
	userPictureOnly: boolean = false;
	hideMenuOnClick: boolean = false;
	user: Profile;
	userMenu = [];

	selectedItem = '';
	currentLanguage: Language = null;
	currentTheme = null;
	languages: Language[] = [];

	notifications$ = null;


	public constructor(
		private sidebarService: NbSidebarService,
		private menuService: NbMenuService,
		private themeService: NbThemeService,
		private router: Router,
		private notificationsService: NotificationsService,
		private layoutService: LayoutService,
		private authService: AuthService,
		private breakpointService: NbMediaBreakpointsService,
		private translateService: TranslateService,
		private sharedService: SharedService
	) {
		this.notifications$ = this.notificationsService.notifications$;
		this.materialTheme$ = this.themeService.onThemeChange()
			.pipe(map(theme => {
				const themeName: string = theme?.name || '';
				return themeName.startsWith('material');
			}));
			
		this.userMenu = [
			{ title: `${this.translateService.instant('header.profile')}`, icon: 'person-outline', data: { id: 1 } },
			{ title: `${this.translateService.instant('header.preferences')}`, icon: 'settings-2-outline', data: { id: 3 } },
			{ title: `${this.translateService.instant('header.logOut')}`, icon: 'log-out-outline', data: { id: 2 } }];

		this.selectedItem = this.sharedService.getCurrentLanguage();
		this.currentLanguage = this.sharedService.getLanguageByShortName(this.selectedItem);
		this.languages = this.sharedService.getLanguages();
	}

	ngOnInit() {

		this.currentTheme = this.sharedService.getThemePreference();
		this.subs.add(this.menuService.onItemClick()
			.subscribe((event) => {
				const role = this.authService.getRole();
				let route = '';
				switch (role) {
					case RolesEnum.employee:
					case RolesEnum.manager:
						route = '/management';
						break;
					case RolesEnum.admin:
						route = '/auth';
						break;
					case RolesEnum.customer:
						route = '/customer';
						break;
					default:
						route = '/auth';
						break;
				}

				if (event && event.item.data?.id === 1) {
					this.router.navigate([`${route}/profile`]);
					return;
				}

				if (event && event.item.data?.id === 2) {
					this.authService.logout();
					return;
				}

				if (event && event.item.data?.id === 3) {
					this.router.navigate([`${route}/preferences`]);
					return;
				}

				this.sidebarService.collapse('menu-sidebar');
			}));


		this.user = this.authService.getCurrentLoggedUser();

		const { xl } = this.breakpointService.getBreakpointsMap();
		const { is } = this.breakpointService.getBreakpointsMap();

		this.subs.add(this.themeService.onMediaQueryChange()
			.pipe(
				map(([, currentBreakpoint]) => currentBreakpoint),
				takeUntil(this.destroy$),
			)
			.subscribe((currentBreakpoint) => {
				this.userPictureOnly = currentBreakpoint.width < xl;
				this.hideMenuOnClick = currentBreakpoint.width <= is;
			}));

		this.subs.add(this.themeService.onThemeChange()
			.pipe(
				map(({ name }) => name),
				takeUntil(this.destroy$),
			)
			.subscribe(themeName => {
				this.currentTheme = themeName;
			}));
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
	}



	toggleSidebar(): boolean {
		this.sidebarService.toggle(true, 'menu-sidebar');
		this.layoutService.changeLayoutSize();

		return false;
	}

	navigateHome() {
		this.menuService.navigateHome();
		return false;
	}
}
