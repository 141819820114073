import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import { environment } from '../../../environments/environment';
import { OrderBehaviorService } from '../order/services/order-behavior.service';

@Injectable({ providedIn: 'root' })
export class CustomerSignalRService {
	hasEstablishedConnection = false;
	constructor(private orderBehaviorService: OrderBehaviorService) { }
	private hubConnection: signalR.HubConnection

	public startConnection(accessToken: string): void {
		if (!accessToken) {
			console.log('access token invalid');
			return;
		}

		if (this.hasEstablishedConnection) {
			return;
		}
		const accessTokenOptions = {
			accessTokenFactory: () => {
				return accessToken;
			}
		}
		this.hubConnection = new signalR.HubConnectionBuilder()
			// .configureLogging(signalR.LogLevel. Trace)
			.withUrl(`${environment.baseSignalRUrl}/order`, accessTokenOptions)
			.withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000])
			.build()
		this.hubConnection
			.start()
			.then(() => this.subscribeToEvents())
			//   .then(() => this.getConnectionId())
			.catch(err => console.log('Error while starting connection: ' + err))
	}


	public subscribeToEvents(): void {
		if (this.hasEstablishedConnection) {
			return;
		}
		this.hasEstablishedConnection = true;
		console.log('SignalR customer subscribing to events');
		this.hubConnection.on('userNotifications', (data) => {
			// const message: any = plainToClass(any, data);
			switch (data.type) {
				case CustomerSignalRMessageType.roundRejected:
				case CustomerSignalRMessageType.roundAccepted: {
					setTimeout(() => {
						this.orderBehaviorService.roundStateChanged$.next(data);
					}, 200);
					break;
				}

				case OrderSignalRMessageType.orderCompleted:
				case OrderSignalRMessageType.orderRejected: {
					setTimeout(() => {
						this.orderBehaviorService.orderStateChanged$.next(data);
					}, 200);
					break;
				}
				default:
					break;
			}
		})
	}

}


export enum CustomerSignalRMessageType {
	roundAccepted = "round_accepted",
	roundRejected = "round_rejected",
}

export enum OrderSignalRMessageType {
	orderCompleted = "order_completed",
	orderRejected = "order_rejected",
}