<div class="header-container">
	<a href="#" class="sidebar-toggle" (click)="toggleSidebar()">
		<nb-icon [icon]="(materialTheme$ | async) ? 'menu-outline' : 'menu-2-outline'"></nb-icon>
	</a>
	<a class="logo" (click)="navigateHome()"><span>Hasty Tasty</span></a>
	<nb-actions size="small">
		<!-- <nb-action [badgeText]="(notifications$ | async)?.length" badgeStatus="success" [nbPopover]="template" icon="search-outline"
			class="px-0">
		</nb-action> -->


	</nb-actions>
</div>

<ng-template #template let-data>
	<nb-card class="popover-card">
		<nb-card-body *ngFor="let notification of (notifications$ | async)">
			<span>{{notification.header}}</span><br>
			<span>{{notification.text}}</span>
		</nb-card-body>
	</nb-card>
</ng-template>
