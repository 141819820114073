import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OrderExtended } from '../../../management/configuration/order-summary/order-summary.component';
import { OrderStateChanged, Round, RoundStateChanged } from '../models/models';

@Injectable({
	providedIn: 'root'
})
export class OrderBehaviorService {
	public orderRoundUpdated$ = new BehaviorSubject<Round>(null);
	public orderRoundCreated$ = new BehaviorSubject<Round>(null);
	public newOrderCreated$ = new BehaviorSubject<OrderExtended>(null);

	public roundStateChanged$ = new BehaviorSubject<RoundStateChanged>(null);

	public orderStateChanged$ = new BehaviorSubject<OrderStateChanged>(null);



	public roundUpdated(round: Round): void {
		if (round) {
			this.orderRoundUpdated$.next(round);
		}
	}

	public roundCreated(round: Round): void {
		if (round) {
			this.orderRoundCreated$.next(round);
		}
	}

	public newOrderCreated(order: OrderExtended): void {
		this.newOrderCreated$.next(order);
	}

	public roundStateChanged(roundStateChangedData: RoundStateChanged): void {
		this.roundStateChanged$.next(roundStateChangedData);
	}

	public orderStateChanged(orderStateChangedData: OrderStateChanged): void {
		this.orderStateChanged$.next(orderStateChangedData);
	}
}