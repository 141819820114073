import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LoadingService {
	public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	showLoader(): void {
		setTimeout(() => {
			this.isLoading$.next(true);
		});
	}

	hideLoader(): void {
		setTimeout(() => {
			this.isLoading$.next(false);
		});

	}
}