import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { NbToastrService, NbIconConfig } from '@nebular/theme';

@Injectable()
export class PwaService {
	constructor(private swUpdate: SwUpdate, private toastrService: NbToastrService) {
		this.swUpdate.available.subscribe(event => {
			if (this.askUserToUpdate()) {
				window.location.reload();
			}
		});

		// window.addEventListener('beforeinstallprompt', event => {
		// 	this.promptEvent = event;
		//   });

	}

	private askUserToUpdate(): boolean {
		const iconConfig: NbIconConfig = { icon: 'refresh-outline', pack: 'eva' };
		this.toastrService.show('Message', `A newer version is available!`, iconConfig);
		return false;
	}

	// installPwa(): void {
	// 	this.Pwa.promptEvent.prompt();
	//   }
}