import { Injectable } from '@angular/core';
import { Menu } from '../../customers/order/models/models';
import { Constants } from '../Constants';
import { LocalStoreManager } from './local-store-manager.service';

@Injectable({ providedIn: 'root' })
export class TenantCacheService {
	currentTenantId: string;
	menu: Menu;

	constructor(private localStoreManager: LocalStoreManager) {

	}

	setCurrentTenantId(tenantId: string): void {
		this.currentTenantId = tenantId;
		this.localStoreManager.savePermanentData(tenantId, Constants.CURRENT_TENANT)
	}
}
