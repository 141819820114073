import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Component({
	selector: 'ht-auth-callback',
	templateUrl: './auth-callback.component.html',
	styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit {

	error = false;
	constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) { }

	ngOnInit() {
		// check for error
		if (this.route.snapshot.fragment && this.route.snapshot.fragment.indexOf('error') >= 0) {
			this.error = true;
			return;
		}

		this.authService.completeAuthentication().then(() => {
			const user = this.authService.getCurrentLoggedUser();
			this.authService.routeToCorrectModule(user?.role, user?.sub);
		});
	}
}
