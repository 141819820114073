import { Injectable } from '@angular/core';
import { ApiClientService } from './api-client.service';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	constructor(private apiClientService: ApiClientService) {

	}
	private currentUserId: string = ''



	public async saveUserInformationAsync(user: HtUser): Promise<void> {
		const result = await this.apiClientService.postIdentityData<HtUser, any>(`user-management/${user.id}`, { user });
		if (result) {
			console.log(result)
		}
	}

	public async getUserInformationAsync(id: string): Promise<HtUser> {
		const result = await this.apiClientService.getIdentityData<HtUser, any>(`user-management/${id}`);
		if (result) {
			return result
		}
	}
}


export class HtUser {
	id: string;
	firstName: string;
	lastName: string;
	email: string;
	userName: string;
	birthDate: Date;
	role: string;
	avatarImage: string;
}