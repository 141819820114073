import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HammerModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import {
    NbAccordionModule, NbActionsModule, NbAlertModule, NbButtonModule, NbCardModule, NbCheckboxModule, NbDatepickerModule, NbIconModule,
    NbInputModule, NbLayoutModule, NbListModule, NbMenuModule, NbPopoverModule, NbRadioModule, NbSearchModule, NbSelectModule, NbSpinnerModule, NbTableModule,
    NbTabsetModule, NbTimepickerModule, NbTreeGridModule, NbUserModule
} from '@nebular/theme';
import { TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ThemeModule } from '../@theme/theme.module';
import { MyOrderComponent } from '../customers/order/my-order/my-order.component';
import { AllergenesComponent } from './components/allergenes/allergenes.component';
import { HastySchedulerComponent } from './components/hasty-scheduler/hasty-scheduler.component';
import { MapComponent } from './components/map/map.component';
import { SpiceLevelComponent } from './components/spice-level/spice-level.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { LogPipe } from './pipes/log.pipe';
import { OrderAmountPipe } from './pipes/order-amount.pipe';
import { OrderItemsSummaryPipe } from './pipes/order-items-summary.pipe';
import { OrderStateNamePipe } from './pipes/order-state-name.pipe';
import { RoundAmountPipe } from './pipes/round-amount.pipe';
import { SpiceLevelPipe } from './pipes/spice-level.pipe';
import { UserAmountPipe } from './pipes/user-amount.pipe';
@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ThemeModule,
        NbUserModule,
        NbActionsModule,
        NbRadioModule,
        NbSelectModule,
        NbListModule,
        NbButtonModule,
        NbTabsetModule,
        NbCardModule,
        NbTreeGridModule,
        NbSearchModule,
        NbIconModule,
        NbInputModule,
        NbTableModule,
        NbLayoutModule,
        NbCheckboxModule,
        NbAccordionModule,
        TranslateModule,
        NbPopoverModule,
        NbMenuModule,
        NbAlertModule,
        NbDatepickerModule,
        NbTimepickerModule,
        QRCodeModule,
		HammerModule,
        NbSpinnerModule,
        ImageCropperModule
    ],
    declarations: [
        HastySchedulerComponent,
        RoundAmountPipe,
        OrderAmountPipe,
        SpiceLevelPipe,
        OrderStateNamePipe,
        OrderItemsSummaryPipe,
        UserAmountPipe,
        LogPipe,
        MyOrderComponent,
        MapComponent,
        AllergenesComponent,
        SpiceLevelComponent,
        AutofocusDirective,
    ],
    exports: [
        CommonModule,
        RouterModule,
        RoundAmountPipe,
        OrderAmountPipe,
        SpiceLevelPipe,
        OrderStateNamePipe,
        OrderItemsSummaryPipe,
        UserAmountPipe,
        LogPipe,
        FormsModule,
        ReactiveFormsModule,
        HastySchedulerComponent,
        ThemeModule,
        NbUserModule,
        NbActionsModule,
        NbRadioModule,
        NbSelectModule,
        NbListModule,
        NbButtonModule,
        NbTabsetModule,
        NbCardModule,
        NbTreeGridModule,
        NbSearchModule,
        NbIconModule,
        NbInputModule,
        NbTableModule,
        NbLayoutModule,
        NbCheckboxModule,
        NbAccordionModule,
        TranslateModule,
        NbMenuModule,
        NbAlertModule,
        NbDatepickerModule,
        NbTimepickerModule,
        NbPopoverModule,
        QRCodeModule,
        MyOrderComponent,
        AutofocusDirective,
		HammerModule,
        MapComponent,
        AllergenesComponent,
        SpiceLevelComponent,
        NbSpinnerModule,
        ImageCropperModule
    ]
})
export class SharedModule {
}
