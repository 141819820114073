import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
	NbActionsModule,
	NbBadgeModule,
	NbButtonModule,
	NbCardModule,
	NbCheckboxModule,
	NbContextMenuModule,
	NbIconModule,
	NbInputModule,
	NbLayoutModule,
	NbMenuModule,
	NbPopoverModule,
	NbSearchModule,
	NbSelectModule,
	NbSidebarModule,
	NbThemeModule,
	NbUserModule,
} from '@nebular/theme';

import {
	FooterComponent,
	HeaderComponent,
	PreferencesComponent,
	SearchInputComponent,
} from './components';
import {
	OneColumnLayoutComponent,
	ThreeColumnsLayoutComponent,
	TwoColumnsLayoutComponent,
} from './layouts';
import {
	CapitalizePipe,
	NumberWithCommasPipe,
	PluralPipe,
	RoundPipe,
	TimingPipe,
} from './pipes';
import { DARK_THEME } from './styles/theme.dark';
import { DEFAULT_THEME } from './styles/theme.default';

const NB_MODULES = [
	NbLayoutModule,
	NbMenuModule,
	NbPopoverModule,
	NbUserModule,
	NbBadgeModule,
	NbActionsModule,
	NbSearchModule,
	NbSidebarModule,
	NbContextMenuModule,
	NbButtonModule,
	NbSelectModule,
	NbInputModule,
	NbIconModule,
	NbEvaIconsModule,
	NbCardModule,
	NbCheckboxModule
];
const COMPONENTS = [
	HeaderComponent,
	FooterComponent,
	SearchInputComponent,
	OneColumnLayoutComponent,
	PreferencesComponent,
	ThreeColumnsLayoutComponent,
	TwoColumnsLayoutComponent,
];
const PIPES = [
	CapitalizePipe,
	PluralPipe,
	RoundPipe,
	TimingPipe,
	NumberWithCommasPipe,
];

@NgModule({
	imports: [CommonModule, ...NB_MODULES],
	exports: [CommonModule, ...PIPES, ...COMPONENTS],
	declarations: [...COMPONENTS, ...PIPES],
})
export class ThemeModule {
	static forRoot(): ModuleWithProviders<ThemeModule> {
		return {
			ngModule: ThemeModule,
			providers: [
				...NbThemeModule.forRoot(
					{ name: 'midDark' },
					[DEFAULT_THEME, DARK_THEME, { name: 'midDark' }],
				).providers,
			],
		};
	}
}
