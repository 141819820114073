import { Component } from '@angular/core';
import { NbSidebarService } from '@nebular/theme';
import { AuthService } from '../../../auth/auth.service';
@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  templateUrl: './one-column.layout.html',
})
export class OneColumnLayoutComponent {
	public constructor(
		private sidebarService: NbSidebarService,
		private authService: AuthService
	) {}

	toggleSidebar(): boolean {
		this.sidebarService.toggle(true, 'menu-sidebar');
		return false;
	}

	// logout () {
	// 	this.authService.logout();
	// 	return;
	// }
}
