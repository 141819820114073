import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TenantCacheService } from './tenant-cache.service';

@Injectable({
	providedIn: 'root',
})
export class ApiClientService {
	private header: HttpHeaders;
	private responseType = 'json';
	constructor(private readonly httpClient: HttpClient, private tenantCacheService: TenantCacheService, private toastrService: NbToastrService) {
		this.header = new HttpHeaders().set('Access-Control-Allow-Origin', '*');

		this.responseType = 'json';
	}

	getData<T>(url: string, params: HttpParams = new HttpParams(), responseType: any = this.responseType): Promise<T> {
		const endpoint = `${environment.baseApiUrl}/${url}`;
		return this.httpClient.get<T>(endpoint, {
			headers: this.header,
			params: params,
			responseType: responseType,
		}).toPromise().then(result => {
			return result;
		}).catch(error => { 
			return null;
		});
	}

	postData<T, U>(url: string, data: U, params: HttpParams = new HttpParams()): Promise<T> {
		const endpoint = `${environment.baseApiUrl}/${url}`;
		params.append('tenantId', this.tenantCacheService.currentTenantId);
		// data.set('tenantId', this.tenantCacheService.currentTenantId);
		data['tenantId'] = this.tenantCacheService.currentTenantId;

		return lastValueFrom(this.httpClient.post<T>(endpoint, data, {
			headers: this.header,
			params: params,
		})).then((response) => {
			return response;
		}).catch((response) => {
			this.toastrService.danger(response.error.Message, 'Error');
			return null;
		});
	}


	putData<T, U>(url: string, data: U, params: HttpParams = new HttpParams()): Promise<T> {
		const endpoint = `${environment.baseApiUrl}/${url}`;
		params.append('tenantId', this.tenantCacheService.currentTenantId);
		return lastValueFrom(this.httpClient.put<T>(endpoint, data, {
			headers: this.header,
			params: params
		})).then((response) => {
			return response;
		}).catch((error) => {
			this.toastrService.danger(error.message, 'Error');
			return null;	
		})
	}

	deleteData<T>(url: string, params: HttpParams = null, isNoTenantRequired?: boolean): Promise<any> {
		const endpoint = `${environment.baseApiUrl}/${url}`;
		return this.httpClient.delete(endpoint, {
			headers: this.header,
			params: params
		}).toPromise();
	}

	getIdentityData<T, U>(url: string, params: HttpParams = null): Promise<T> {
		const endpoint = `${environment.baseIdentityApiUrl}/${url}`;
		return this.httpClient.get<T>(endpoint, {
			headers: this.header,
			params: params,
		}).toPromise();
	}


	postIdentityData<T, U>(url: string, data: U, params: HttpParams = null): Promise<T> {
		const endpoint = `${environment.baseIdentityApiUrl}/${url}`;
		return this.httpClient.post<T>(endpoint, data, {
			headers: this.header,
			params: params,
		}).toPromise();
	}

	putIdentityData<T, U>(url: string, data: U, params: HttpParams = null): Promise<T> {
		const endpoint = `${environment.baseIdentityApiUrl}/${url}`;
		return this.httpClient.put<T>(endpoint, data, {
			headers: this.header,
			params: params,
		}).toPromise();
	}1

}
