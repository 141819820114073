import { Component, OnInit } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { Language, SharedService } from '../../../shared/services/shared.service';

@Component({
	selector: 'ht-preferences',
	templateUrl: './preferences.component.html',
	styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent implements OnInit {

	constructor(private themeService: NbThemeService, private sharedService: SharedService, private translateService: TranslateService) { }
	selectedItem = '';
	currentLanguage: Language = null;
	languages: Language[] = [];

	currentTheme = null;
	themes = [
		{
			value: 'dark',
			name: 'Dark',
		},
		{
			value: 'default',
			name: 'Light',
		},
		{
			value: 'midDark',
			name: 'Mid-Dark',
		}
	];
	ngOnInit(): void {
		this.currentTheme = this.sharedService.getThemePreference();
		this.selectedItem = this.sharedService.getCurrentLanguage();
		this.currentLanguage = this.sharedService.getLanguageByShortName(this.selectedItem);
		this.languages = this.sharedService.getLanguages();
	}

	changeTheme(themeName: string) {
		this.themeService.changeTheme(themeName);
		this.sharedService.saveThemePreference(themeName);
	}

	languageChanged(newLanguage: string) {
		if (newLanguage) {
			this.translateService.use(newLanguage);
			this.sharedService.changeLanguage(newLanguage);
			window.location.reload();
		}
	}

	savePreferences(): void {

	}

}
