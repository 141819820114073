<nb-alert style="position:absolute; z-index: 2000; margin: 5px" class="update-alert" (click)="update()" *ngIf="showUpdateAvailable" status="info">
	<div class="row">
		<div class="col-10">
			{{ 'login.newVersionAlertMessage' | translate }}
		</div>
		<div class="col-2">
			<a (click)="removeUpdateAlert()">
				<nb-icon class="remove-update-information" [options]="{ animation: { type: 'pulse' } }"	icon="close-circle-outline"></nb-icon>
			</a>
		</div>
	</div>
</nb-alert>

<div [nbSpinner]="showGlobalLoader$ | async">
	<router-outlet></router-outlet>
</div>
