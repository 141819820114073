import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import { plainToClass } from 'class-transformer';
import { environment } from '../../../environments/environment';
import { OrderBehaviorService } from '../../customers/order/services/order-behavior.service';
import { NotificationsService } from '../../shared/services/notifications.service';
import { IncommingOrderService } from '../configuration/incomming-orders/incomming-order.service';
import { OrderExtended } from '../configuration/order-summary/order-summary.component';
@Injectable({ providedIn: 'root' })
export class ManagerSignalRService {
	hasEstablishedConnection = false;
	constructor(private incommingOrderService: IncommingOrderService, private orderBehaviorService: OrderBehaviorService, private notificationsService: NotificationsService) { }
	private hubConnection: signalR.HubConnection

	public startConnection(accessToken: string): void {
		if (!accessToken) {
			console.log('access token invalid');
			return;
		}

		if (this.hasEstablishedConnection) {
			return;
		}
		const accessTokenOptions = {
			accessTokenFactory: () => {
				return accessToken;
			}
		}
		this.hubConnection = new signalR.HubConnectionBuilder()
			// .configureLogging(signalR.LogLevel. Trace)
			.withUrl(`${environment.baseSignalRUrl}/order`, accessTokenOptions)
			.withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000])
			.build()
		this.hubConnection
			.start()
			.then(() => this.subscribeToEvents())
			//   .then(() => this.getConnectionId())
			.catch(err => console.log('Error while starting connection: ' + err))
	}


	public subscribeToEvents(): void {
		if (this.hasEstablishedConnection) {
			return;
		}
		this.hasEstablishedConnection = true;
		console.log('SignalR manager subscribing to events');
		this.hubConnection.on('employeesNotifications', (data: any) => {
			const message: ManagerSignalRMessage = plainToClass(ManagerSignalRMessage, data);
			switch (message.type) {
				case SignalRMessageType.orderCreated:
					setTimeout(() => {
						this.orderBehaviorService.newOrderCreated(data.orderExtended);
						this.notificationsService.addNewNotification('New order has been created!', '');
					}, 200);
					break;
				case SignalRMessageType.roundCreated:
					setTimeout(() => {
						this.notificationsService.addNewNotification('Another round has been ordered!', '');
						this.orderBehaviorService.roundCreated(data.round);
					}, 200);
					break;
				case SignalRMessageType.roundUpdated:
					setTimeout(() => {
						this.notificationsService.addNewNotification('A pending round has been updated!', '');
						this.orderBehaviorService.roundUpdated(data.round);
					}, 200);
					break;
				default:
					break;
			}
		})
	}

}

export class ManagerSignalRMessage {
	type: string;
	order: OrderExtended;

}

export enum SignalRMessageType {
	orderCreated = "order_created",
	roundCreated = "round_created",
	roundUpdated = "round_updated",

}