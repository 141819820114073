import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private authService: AuthService, private router: Router) {

	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let clonedRequest: HttpRequest<any>;

		const idToken = this.authService.accessToken;
		const headers = req.headers.append('App-Version', '1.0');

		if (idToken) {
			clonedRequest = req.clone({
				headers: headers.set('Authorization', 'Bearer ' + idToken)
			});
		} else {
			clonedRequest = req.clone({
				headers: headers
			});
		}

		return next.handle(clonedRequest);
	}
}
