import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';

export const routes: Routes = [
	{ path: 'auth-callback', component: AuthCallbackComponent },
	{
		path: 'management',
		loadChildren: () => import('./management/management.module')
			.then(m => m.ManagementModule),
	},
	{
		path: 'customer',
		loadChildren: () => import('./customers/customers.module')
			.then(m => m.CustomersModule),
	},
	{
		path: 'admin',
		loadChildren: () => import('./admin/admin.module')
			.then(m => m.AdminModule),
	},
	{
		path: 'auth',
		loadChildren: () => import('./auth/auth.module')
			.then(m => m.AuthModule),
	},
	{ path: '', redirectTo: 'auth', pathMatch: 'full' },
	{ path: '**', redirectTo: 'auth' },
];

const config: ExtraOptions = {
	useHash: false,
};

@NgModule({
	imports: [
		RouterModule.forRoot(routes, config),
		TranslateModule
	],
	exports: [RouterModule, TranslateModule],
})
export class AppRoutingModule {
}
